export const commonConstants = {
  STREAMING_PROVIDERS: 'STREAMING_PROVIDERS',
  STREAMING_PROVIDERS_FAILURE: 'STREAMING_PROVIDERS_FAILURE',
  CM_CREATE_AWS_DEST: 'CM_CREATE_AWS_DEST',
  CM_CREATE_AWS_DEST_FAILURE: 'CM_CREATE_AWS_DEST_FAILURE',
  CREATE_LUC_DEST: 'CREATE_LUC_DEST',
  CREATE_LUC_DEST_FAILURE: 'CREATE_LUC_DEST_FAILURE',
  SET_LUC_DEST: 'SET_LUC_DEST',
  SET_LUC_DEST_FAILURE: 'SET_LUC_DEST_FAILURE',
  OTHER_LOADING: 'OTHER_LOADING',
  OTHER_LOADING_FALSE: 'OTHER_LOADING_FALSE',
  DISABLE_SUBMIT_TRUE: 'DISABLE_SUBMIT_TRUE',
  DISABLE_SUBMIT_FALSE: 'DISABLE_SUBMIT_FALSE',
  OVP_DATA: 'OVP_DATA',
  OVP_DATA_FAILURE: 'OVP_DATA_FAILURE',
  UPDATE_OVP: 'UPDATE_OVP',
  UPDATE_OVP_FAIL: 'UPDATE_OVP_FAIL',
  UNIT_STREAM_LOADING: 'UNIT_STREAM_LOADING',
  SET_PROGRESSBAR_DATA: 'SET_PROGRESSBAR_DATA',
  SHOW_PROGRESSBAR: 'SHOW_PROGRESSBAR',
  HIDE_PROGRESSBAR: 'HIDE_PROGRESSBAR',
  SOLO_VERSION: '3.2.8R',
};
